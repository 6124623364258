import React from 'react';

import Layout from '../components/layout.js';
import '../components/layout.css';

const Index = () => {
      return (
            <Layout>
                  Index page
            </Layout>
      )
}

export default Index;